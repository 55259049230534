@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

:root{
  --orange:#fe831c;
  --orange-l:#f07b55;
  --orange-light: #f58d72;
  --white:#fafafa;
  --brown:#6c6c6c;
  --light-gray:#97989a;
  --very-dark-gray:#2c2c2c;
  --dark-gray:#474747;
  --very-light-gray:#dcdcdc;
  --black:rgb(0, 0, 0);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Epilogue", sans-serif;
  
    a{
      color: white !important;
      text-decoration: none;
    }
}



//navbar version de escritorio
.navbar-desktop{
  display: flex;
  justify-content: space-between;
  padding: 10px 150px;
  background-color: var(--white);
  align-items: center;
  border-bottom: 1px solid var(--orange);
    
    img{
     height: 100px !important;
    }
    li{
      cursor: pointer;
    }
}

.nav_menu{
  display: flex;
  list-style: none;
  gap: 2em;
  font-size: 16px;
  font-family: "Epilogue", sans-serif;
  align-items: center;

    a{
      color: var(--very-dark-gray) !important;
    }

    .social-media{
      display: flex;
      gap: 10px;
      // margin-right: 5px;
      a{
        color: var(--brown) !important;
        &:hover{
          color: var(--orange) !important;
          transition: 0.5s;
        }
      }
    }
}


.nav_menu_option{
  position: relative;
  color: var(--very-dark-gray);
  cursor: pointer;
}

//efecto linea inferior navbar
.nav_menu_option::after{
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;  
  width: 100%;
  height: 3px;
  background-color: var(--orange);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s;
}

.nav_menu_option:hover::after{
  transform: scaleX(1);
  transform-origin: left;
}

.navbar-mobile{
  display: none;
}

//menu hamburguesa
.hambur{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3px;
  cursor: pointer;
  z-index: 3;  
}

.menu-hambur{
  width: 25px;
  height: 3px;
  background-color: var(--brown);
  transition: 0.3s;
}

.active .menu-hambur:nth-child(1){
  transform: translateY(6px)
  rotate(135deg);
}

.active .menu-hambur:nth-child(2){
  transform: scale(0);
}

.active .menu-hambur:nth-child(3){
  transform: translateY(-6px)
  rotate(-135deg);
}

//slider2
.slider-container2{
  width: 100%;
  padding: 0 150px;
  position: relative;
  display: flex;
  .text-banner2{
    // width: 50%;
    display: flex !important;
    flex-direction: column;
    // height: 100%;
    // align-items: center !important;
    justify-content: center !important;
    gap: 15px;
      span{
        color: var(--orange);
      }
      h2{
        font-size: 40px;
      }
  
      p{
        font-size: 17px;
        font-weight: 500;
      }
  
      h3{
        position: absolute;
        top: 50%;
        left: 50%;
      }
  }
  
  .slick-list{
    height: 500px !important;
    width: 550px !important;
  }
  .slick-arrow{
    display: none !important;
  }
  .slider-banner2{
    // width: 50%;
    // height: 500px;
    display: flex !important;
    // align-items: center;
    justify-content: space-around;
    overflow: hidden;
   
    img{
    height: 450px;
    }

    // .familia-2{
    //   height: 350px !important;
    // }
    
    .right-banner{
      width: 50%;
    }

    
    .test2{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

//slider de imagenes
.slider-container{
  width: 100%;
  padding: 0 150px;
  position: relative;
  
  
  .slider-banner{
    height: 500px;
    display: flex !important;
    align-items: center;
    justify-content: space-around;
   
    img{
      height: 500px;
    }

    .text-banner{
      display: flex;
      flex-direction: column;
      gap: 15px;
        span{
          color: var(--orange);
        }
    }

    h2{
      font-size: 40px;
    }

    p{
      font-size: 17px;
      font-weight: 500;
    }

    h3{
      position: absolute;
      top: 50%;
      left: 50%;
    }
    .test{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

//puntitos de la pasarela de imagenes
.slick-dots{
  bottom: 5vw !important;
  position: relative;
  z-index: 1;
  li{
      button{
          width:14px !important;
          height:14px !important;
          border: 1px solid var(--brown) !important;
          border-radius: 20px;
          &:before{
              position:absolute;
              top:35% !important;
              left:35% !important;
              width:10px !important;
              height:10px !important;
              border-radius: 10px;
              content:"";
              text-align: center;
              opacity:.5;
              color:transparent !important;
              background-color:#fff;
              transform:translate(-50%, -50%);
              transition:all .3s ease-in-out;
          }
      }
      &.slick-active,
      &:hover{
          button{
              border-color: #fe831c;
              &:before{
                  background-color:var(--orange);
                  opacity:1;
              }
          }
      }
  }
}

//flechas del slider
.slick-prev, .slick-next{
  width:50px;
  height:50px !important;
  // background-color:rgba(190, 184, 184, 0.178) !important;
  border-radius: 10px;
  background: transparent !important;
}
.slick-prev:hover,.slick-next:hover{
  color:#fff !important;
  outline:none;
  background:transparent !important;
  transition: .5s;
}

.slick-prev::before,.slick-next::before{
  color: var(--orange) !important;
  font-size: 25px !important;
  font-weight: 700;
  width: 50px !important;
  height: 50px !important;
  border-radius: 100%;
  
  font-family: "Epilogue" !important;
}

.slick-next{
  right:-40px !important;
}
.slick-prev{
  left: -40px !important;
  
}

.test-1{
  position: relative;
  background-color: gainsboro;
}

//divisor diagonal
.custom-shape-divider-bottom-1664653005 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  
}

.custom-shape-divider-bottom-1664653005 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 140px;
  transform: rotateY(180deg);
  
  
}

.custom-shape-divider-bottom-1664653005 .shape-fill {
  fill: #FFFFFF;
  
}


//Sección de pasos para pedir un prestamo
.steps{
  display: flex;
  gap: 50px;
  // padding: 0 100px;
  width: 70%;
  margin: 0 0 0 auto;
  justify-content: end;
  
  h2{
    text-align: center;
    color: var(--orange);
  }
  
  h3{
    text-transform: uppercase;
    text-align: center;
  }
  p{
    font-size: 14px;
    text-align: justify;
    width: 95%;
    margin: 0 auto;
  }

  div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    width: 300px;
    height: 300px;
    background-color: whitesmoke;
    border: 5px solid var(--white);
    border-radius:30px;
    box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  }

  .step-1{
    position: relative;
    &::before{
      content: "";
      height: 75px;
      width: 5px;
      top: -30%;
      left: 50%;
      background: var(--orange);
      opacity: 0.8;
      position: absolute;
    }
  }

  .step-2{
    position: relative;
    top: 50px;
    &::before{
      content: "";
      height: 150px;
      width: 5px;
      top: -58%;
      left: 50%;
      opacity: 0.8;
      background: var(--orange);
      position: absolute;
    }
  }

  .step-3{
    position: relative;
    top: 100px;
    &::before{
      content: "";
      height: 225px;
      width: 5px;
      top: -86%;
      left: 50%;
      opacity: 0.8;
      background: var(--orange);
      position: absolute;
    }
  }
}


.section-3{
  display: flex;
  width: 100%;
  padding: 0 150px;
  margin: 0 auto;
  background-color: #FFFFFF;
  height: 45vh;
  
  .text-steps{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 100px;    
    padding-right: 40px;
    width: 30%;
    text-align: justify;
    margin: 0 auto;
    
    div{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    h2{
      text-transform: uppercase;
    }
  }
}


//button con efecto hover
.button {
  color: var(--orange);
  width: fit-content;
  padding: 0.8em 1.7em;
  background-color: transparent;
  border-radius: .3em;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: .5s;
  font-weight: 500;
  font-size: 15px;
  border: 1px solid;
  font-family: "Epilogue",sans-serif;
  text-transform: uppercase;
  color: var(--orange);
  z-index: 1;
 }
 
 .button::before, .button::after {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--orange);
  transition: 1s ease;
 }
 
 .button::before {
  top: -1.3em;
  left: -1em;
 }
 
 .button::after {
  left: calc(100% + 1em);
  top: calc(100% + 1.3em);
 }
 
 .button:hover::before, .button:hover::after {
  height: 410px;
  width: 410px;
 }
 
 .button:hover {
  color: var(--white);
 }
 
 .button:active {
  filter: brightness(.8);
 }
 

//SECCION DE BENEFICIOS Y REQUISITOS
.section_4{
  margin: 100px 0;
  position: relative;
}

.section_4_switch{
  width: 320px;
  height: 50px;
  display: flex;
  font-size: 15px;
  border:2px solid var(--orange);
  // padding: 15px 30px;
  justify-content: space-between;
  border-radius: 10px;
  margin: 0 auto 50px auto;
  position: relative;
  z-index: 9999;  
  
  div{
    width: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--very-dark-gray);
    cursor: pointer;
    text-transform: uppercase;
    
  }

  &::before{
    content: "";
    height: 100%;
    width: 2px;
    background: var(--orange);
    position: absolute;
    top: 0%;
    left: 50%;
  }

}

.benefits-active{
  background-color: var(--orange);
  border-radius: 5px 0 0 5px;
}

.requirements-active{
  background-color: var(--orange);
  border-radius: 0 5px 5px 0;
}

.benefits-cards{
  display: grid;
  justify-items: center;
  background-color: var(--dark-gray);
  border-radius: 7px;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  grid-template-columns: repeat(
    auto-fit,
    minmax(
      150px,
      1fr
    )
  );
  gap: 20px;
  width: 70%;
  margin: 0 auto;
  margin-top: 100px;

  .benefit-card{
    position: relative;
    z-index: 999;
    div{
      text-align: center;
      padding: 30px 15px 0 15px;
      border: 1px  var(--brown);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      max-width: 300px;
      min-height: 250px;
      color: var(--white);
    
        h3{
          text-transform: uppercase;
        }
        img{
          width: 130px;
          padding: 20px;
          position: absolute;
          top: -25%;
          background-color: var(--white);
          border-radius:50px;
        }
    }
  }  
}

.requeriments-cards{
  display: grid;
  justify-items: center;
  background-color: var(--dark-gray);
  border-radius:7px;
  grid-template-columns: repeat(
    auto-fit,
    minmax(
      150px,
      1fr
    )
  );
  gap: 20px;
  width: 70%;
  margin: 0 auto;
  margin-top: 100px;

  .requeriment-card{
    position: relative;
    z-index: 999;
    div{
      text-align: center;
      padding: 30px 15px 0 15px;
      border: 1px  var(--brown);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      max-width: 300px;
      min-height: 250px;
      color: var(--white);
  
        img{
          width: 130px;
          padding: 20px;
          position: absolute;
          top: -25%;
          background-color: var(--white);
          border-radius: 50px;
          
        }
    }
  }  
}

//Franja de contacto 
.section-5{
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  border-radius: 20px;
  background-color: var(--white);
  border: 3px solid var(--orange);
  margin-bottom: 30px;
  gap: 15px;
  position: relative;

  .section-5-text{
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .section-5-contact{
    display: flex;
    
      div{
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        color: var(--very-dark-gray);
        
      }
    
  }
    
  }
 
  //floating-card
.card {
  width: 350px;
  height: 80px;
  padding: 0.5rem;
  background: var(--orange);
  border-radius: 8px;
  backdrop-filter: blur(5px);
  position: absolute;
  right: 3%;
  top: 15%;
  font-family: "Epilogue";
  font-size: 16px; 
  border-bottom: 3px solid rgba(255, 255, 255, 0.440);
  border-left: 2px  rgba(255, 255, 255, 0.545) outset;
  box-shadow: -40px 50px 30px rgba(0, 0, 0, 0.280);
  transform: skewX(10deg);
  transition: .4s;
  overflow: hidden;
  color: white;

  h2{
    padding:25px 10px;
    text-transform: uppercase;
  }

  p{
    font-size: 15px;
  }

  button{
    background-color: transparent;
    border: 1px solid var(--white);
    cursor: pointer;
    margin-top: 10px;
    padding: 12px;
    width: 150px;
    position: relative;
    left: 50%;
    color: var(--white);
    font-weight: 500;

    &:hover{
      background-color: white;
      color: var(--very-dark-gray);
      transition: 0.3s linear;
    }
  }
}

.card:hover {
  height: 180px;
  transform: skew(0deg);
}

.align {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-self: flex-start;
}

.card h1 {
  text-align: center;
  margin: 1.3rem;
  color: rgb(218, 244, 237);
  text-shadow: -10px 5px 10px rgba(0, 0, 0, 0.573);
}

 //footer
 .footer{
  width: 100%;
  background-color: var(--very-dark-gray);
  // height: 200px; 
  padding: 50px 100px;
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 1fr;
  position: relative;
  
  p{
    font-size: 12px;
    color: var(--white);
    text-align: justify;
    margin: auto auto 0 auto;
  }
  
  .footer-left{
    display: flex;
    flex-direction: column;
    gap: 20px;

    div{
      display: flex;    
      gap: 10px;
      color: white !important;
      
      a{
        text-decoration: none;
      }

      a:hover{
        color: var(--orange) !important;
        transition-duration: 0.5s;
        
      }
    }

    p{
      opacity: 0.8;
    }

    img{
      width: 200px;
    }
  }

  .footer-mid{
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 15px;
    font-weight: 300;
    
    .footer-menu{
      opacity: 0.6;
      font-size: 14px;
      &:hover{
        color: var(--orange) !important;
        opacity: 1;
        transition: 0.3s;
      }
    }
    
    h3{
      color: var(--white);
      text-transform: uppercase;
      font-size: 16px;
    }

    p{
      font-size: 16px;
    }
    
  }

  .footer-right{
    display: flex;
    flex-direction: column;
    gap: 10px;

    h3{
      color: var(--white);
      font-size: 16px;
    }

    p{
      font-size: 12px;
      color: var(--white);
      opacity: 0.6;
    }
  }
 }

 .container-form{
  width: 80%;
  margin: 100px auto;
  
  .text-form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    h2{
      color: var(--brown);
    }
    h3{
      color: var(--orange);
    }
  }
  
  .contact-form{
    width: 60%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 50px;
    padding: 40px;
    justify-items: center;
    align-items: center;
    background-color: whitesmoke;
    gap: 10px;
    border-radius: 20px;
    button{
      margin-top: 40px;
      grid-row: 4;
    }
    position: relative;
    
  }

  .wsapp{
    color: #1bd741;
    background-color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    
    &:hover{
      color: var(--white);
      
      svg{
        color: var(--white);
      }
    }
  }
  
  .wsapp::before, .wsapp::after {
    background-color: #1bd741 !important;
  }

  svg{
    color: #1bd741;
    position: relative;
    left: 10px;
  }

  .contact{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .contact-ws{
    grid-column:2/4;
    font-size: 15px;
  }
}


//Estilos del Input
.form__group {
  position: relative;
  padding: 20px 0 0;
  margin-top: 10px;
  width: 100%;
  max-width: 180px;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--brown);
  outline: 0;
  // font-size: 15px;
  color: var(--dark-gray);
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 15px !important;
  color: var(--brown);
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 13px !important;
  color: var(--orange);
  pointer-events: none;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 2px;
  border-image: linear-gradient(to right, var(--brown), var(--orange));
  border-image-slice: 1;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 13px !important;
  color: var(--orange);
  font-weight: 700;
}

/* reset input */
.form__field:required, .form__field:invalid {
  box-shadow: none;
}


//Ruta de Sobre Nosotros
.about-us{
  display: flex;
  flex-direction: column;
  gap:  25px;
  justify-content: center;
  align-items: center;
  margin:60px;

  .about-us-img{
    img{
      width: 500px;
    }
  }
  .about-us-text{
    display: flex;
    flex-direction: column;
    gap: 25px;
    p{
      width: 40%;
      text-align: justify;
      margin: 0 auto;
      line-height: 20px;
    }
    h3{
      width: 40%;
      margin: 0 auto;
      margin-bottom: 8px;
      color: var(--orange);
    }
  }
  .about-us-contact{
    display: flex;
    flex-direction: row;
    width: 40%;
    justify-content: space-evenly;
    gap: 10px;
    background-color: var(--very-dark-gray);
    padding: 20px;
    border-radius: 10px;
    p{
      display: flex;
      flex-direction: row;
      text-align: center;
      font-weight: 500;
      gap: 5px;
      color: white;
      span{
        font-weight: 700;
        color: var(--orange);
      }
    }
  }

  .social-media{
    display: flex;
    gap: 20px;
    a{
      color: var(--very-dark-gray) !important;
      &:hover{
        color: var(--orange) !important;
        transition: 0.3s;
      }
    }
  }
}


//Ruta de preguntas frecuentes
.container-questions{
  display: flex;
  flex-direction: column;
  gap:  25px;
  width: 60%;
  text-align: justify;
  margin: 70px auto 100px auto;

  h2{
    color: var(--very-dark-gray);
  }

  .social-media{
    display: flex;
    
    gap: 20px;
    a{
      color: var(--very-dark-gray) !important;
      &:hover{
        color: var(--orange) !important;
        transition: 0.3s;
      }
    }
  }
  .question{
      display: flex;
      flex-direction: column;
      gap: 15px;
      background-color: var(--very-light-gray);
      padding: 20px;
      border-radius: 15px;
      max-height: 55px;
      overflow: hidden;
      transition: max-height 1s ease-in;
      cursor:pointer;

      .top-question{
        display: flex;
        justify-content: space-between;
        
        svg{
          color: var(--very-dark-gray);
        }
      }
      h3{
        color: var(--dark-gray);
        width: fit-content;
        border-radius: 10px;
        padding-bottom: 8px;
      }
      p{
        color: var(--dark-gray);
      }

      ol{
        color: var(--dark-gray);
        padding-left: 30px;
        padding-top: 5px;
      }
  }
  .is-open{
    max-height: 400px !important;
  }
}

.container-contact{
  display: flex;
  width: 100%;
  height: 80vh;
  
  
  .contact-left{
    width: 70%;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 35px;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    h2{
      position: relative;
      border-left:5px solid var(--orange);
      z-index: 999;
      color: var(--white);
      width: 70%;
      margin-left: 70px;
      padding:10px;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 30px;
    }
    p{
      position: relative;
      z-index: 999;
      color: var(--white);
      width: 70%;
      margin-left: 70px;
      line-height: 23px;
    }
    .button{
      margin-left: 70px;
    }
    .imgBG{
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      
    }
    &::after{
      content: "";
      // height: 80vh;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.74);
    }
  }
  .contact-right{
    width: 30%;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    padding: 0 40px;

    h3{
      text-transform: uppercase;
    }

    svg{
      color: var(--brown) !important;
    }

    .contact-social-icons{
      svg{
        color: var(--orange) !important;
        margin-right: 10px;
      }
    }
    .contact-right-div{
      display: flex;
      align-items: center;
      gap: 15px;
      div{
        h4{
          padding-bottom: 8px;
        }
      }

     
    }
  }
}

.ws-fixed{
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--white);
  width: 50px;
  height: 50px;
  border-radius: 10px;
  z-index: 9999;

  &:hover{
    animation: ws-animation 0.7s infinite;
  }
}

@keyframes ws-animation{
  0%{
    transform: scale(1.0);
  }
  100%{
    transform: scale(1.1);
  }
}

//Media para laptops
@media only screen and (min-width:"1023px") and (max-width:"1500px"){

  .navbar-desktop{
    padding: 10px 50px; 
    justify-content: space-between;
    font-size: 14px;

    .nav_menu{
      gap: 1em;
      font-size: 15px;
    }

    img{
      height: 40px;
    }
  }

  .slider-banner{
    height: 460px !important;
  }

  .section-3{
    padding: 0 30px;
  

    .text-steps{
      padding: 80px 20px 0 20px;
    }
  }

  .steps{
    gap: 15px;
    width: 70%;
    margin: 0;
    

    div{
      width: 280px;
      height: 280px;
    }
  }

  .benefits-cards{
    width: 90%;
  }

  .footer{
    grid-template-columns: 0.6fr 0.5fr 1fr 1fr;
    padding: 50px 50px;
  }

  .container-form{
    width: 100%;
    margin: 70px auto !important;
    padding: 30px 120px !important;
  }
  .contact-form{
    width: 85% !important;
    // margin: 0 auto;
  }

  .contact-ws{
    margin-top: 25px;
  }

  .step-1{
    position: relative;
    &::before{
      content: "";
      height: 73px !important;
      border-radius: 10px;
      width: 5px;
      top: -79px !important;
      left: 50%;
      background: var(--orange);
      opacity: 0.8;
      position: absolute;
    }
  }

  .step-2{
    position: relative;
    top: 50px;
    &::before{
      content: "";
      height: 140px;
      width: 5px;
      top: -57.5% !important;
      border-radius: 10px;
      left: 50%;
      opacity: 0.8;
      background: var(--orange);
      position: absolute;
    }
  }

  .step-3{
    position: relative;
    top: 100px;
    &::before{
      content: "";
      height: 225px !important;
      width: 5px;
      top: -85.3% !important;
      left: 50%;
      border-radius: 5px;
      opacity: 0.8;
      background: var(--orange);
      position: absolute;
    }
  }
  
}

@media only screen and (min-width:"769px") and (max-width:"1022px"){
  .navbar-desktop{
    padding: 10px 40px;
    
    .social-media{
      display: none;
    }
  }

  .slider-container{
    padding: 0 70px !important;

    .slider-banner{
      img{
        height: 350px !important;
      }
    }
  }

  .section-3{
    flex-direction: column;
    height: 100%;
    padding: 0 80px;

    .text-steps{
      width: 100%;
      padding-top: 40px;
    }

    .steps{
      margin: 0;
      justify-content: center;
      width: 100%;
      gap: 20px;
      div{
        
      }

      .step-1{
        position: relative;
        top: 25px;
        &::before{
          content: "";
          display: none;
        }
      }
    
      .step-2{
        position: relative;
        top: 25px;
        &::before{
          content: "";
          display: none;
        }
      }
    
      .step-3{
        position: relative;
        top: 25px;
        &::before{
          content: "";
          display: none;
        }
      }
    }
  }

  .benefits-cards{
    width: 85%;
    // min-height: 280px;
    padding: 0 20px;

    .benefit-card{
      div{
        padding: 0;
        
      }
      p{
        font-size: 15px
      }
    }
  }

  .contact-form{
    width: 90% !important;
  }
}

@media only screen and (min-width:"769px") and (max-width:"1200px"){

  .slider-container{
    padding: 0 100px;

    .slider-banner{

      img{
        height: 400px;
      }
    }
  }

  

  .slider-container2{
    padding: 0 50px !important;

    .slick-list{
      width: 450px !important;
    }

    .slider-banner2{

      img{
        height: 400px !important;
      }
    }
  }

  .footer{
    gap: 10px;
  }
  .footer-right{
    grid-column: 3 / -1;
  }
  .footer-floating-card{
    grid-column: 1;
    // margin-right: 40px;
  }
  .card{
    position: relative;
    margin-left: 25px;
  }

  

  
}

//Media para PC escritorios y monitores mas grandes
@media only screen and (min-width:1501px){
  
  .step-1{
    position: relative;
    &::before{
      content: "";
      height: 76px !important;
      border-radius: 10px;
      width: 5px;
      top: -28.5% !important;
      left: 50%;
      background: var(--orange);
      opacity: 0.8;
      position: absolute;
    }
  }

  .step-2{
    position: relative;
    top: 50px;
    &::before{
      content: "";
      height: 150px;
      width: 5px;
      top: -53.5% !important;
      border-radius: 10px;
      left: 50%;
      opacity: 0.8;
      background: var(--orange);
      position: absolute;
    }
  }

  .step-3{
    position: relative;
    top: 100px;
    &::before{
      content: "";
      height: 222px !important;
      width: 5px;
      top: -78.5% !important;
      left: 50%;
      border-radius: 5px;
      opacity: 0.8;
      background: var(--orange);
      position: absolute;
    }
  }
  
}

//Media para dispositivos moviles 
@media only screen and (max-width:"768px"){

  .slider-container2{
    flex-direction: column;
    padding: 20px 30px 0 30px;
    text-align: center;
    gap: 15px;

    .right-banner{
      display: flex;
      justify-content: center;
    }

    .slick-list{
      width: 290px !important;
      height: 330px !important;
    }
    .text-banner2{
      text-align: center;
      width: 100%;
      margin-top: 30px;
        .text-banner-2{
          text-transform: uppercase;
        }
    }

    .slider-banner2{
      img{
        height: 290px;
        margin: 0 auto;
      }
    }

    h2{
      font-size: 30px !important;
    }

    button{
      margin: 0 auto;
    }
  }


  .footer-floating-card{
    .card{
      width: 250px !important;
      h2{
        font-size: 15px;
        padding: 22px 10px;
      }
      p{
        font-size: 13px;
      }
    }
  }

  .navbar-mobile{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 10px auto;
    
    img{
      height: 80px;
    }

    .menu-navbar-mobile{
      display: none;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 25%;
      top: 100px;
      background-color: #474747;
      z-index: 999;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      padding-left: 35px;
      gap: 30px;
      opacity: 0;

      a{
        width: fit-content;
      }

      &::before{
        content: "";
        position: absolute;
        left: -100%;
        right: 0;
        top: 0px;
        bottom: 0;
        width: 200%;
        background-color: rgba(0, 0, 0, 0.274);
      }
    }
  }

  .slick-prev,.slick-next{
    display: none !important;
  }

  .slider-container{
    padding: 0;
      .slider-banner{
        flex-direction: column;
        height: 600px;
          
          img{
            height: 300px;
          }
          .text-banner{
            text-align: center;
            width: 90%;
            margin-top: 30px;
              .text-banner-1{
                text-transform: uppercase;
              }
          }

          h2{
            font-size: 30px;
          }

          button{
            margin: 0 auto;
          }
      }
  }

  .section-3{
    display: flex;
    flex-direction: column;
    padding: 0;
    height: fit-content;
    align-items: center;
    .text-steps{
      padding: 0;
      width: 80%;
      margin-top: 40px;
      button{
        margin: 15px auto;
      }
    }

    .steps{
      flex-direction: column;
      justify-content: center;
      margin: 50px 0 0 0;
      width: 80%;
      align-items: center;

      div{
        height: 250px;
      }
      .step-1{

        &::before{
          height: 30px;
          top: -35px;
        }
      }
      
      .step-2{
        top: 0;
        &::before{
          height: 50px;
          top: -55px;
        }
      }

      .step-3{
        top: 0;
        &::before{
          height: 50px;
          top: -55px;
        }
      }
    }
  }

  .navbar-desktop{
    display: none;
  }

  .footer{
    grid-template-columns: 1fr;
    padding: 20px;
    gap: 20px;
  }

  .card{
    position: relative;
    width: 320px;
    font-size: 16px;
    height: 70px;
    margin:0 auto 50px 25px;
    h2{
      font-size: 16px;
      padding: 15px 15px;
      
    }
    p{
      font-size: 14px;
      padding: 0 15px;
    }
    button{
      position: relative;
      top: 20px;
      left: 15px;
    }
  }

  .section_4{
    margin-bottom: 80px !important;
  }

  .section_4_switch{
    width: 80%;
    margin: 0 auto 10px auto !important;
  }

  .benefits-cards{
    width: 80%;
    align-items: center !important;
    justify-items: center !important;
    padding-bottom: 40px;
    margin-top: 50px;
    
    .benefit-card{
      
      img{
        position: relative !important;
      }
    }
  }

  .requeriments-cards{
    width: 80%;
    align-items: center !important;
    justify-items: center !important;
    padding-bottom: 40px;
    margin-top: 50px;
    .requeriment-card{
      
      img{
        position: relative !important;
      }
    }
  }

  .section-5{
    width: 80%;
    margin-bottom: 50px;
  }

  .section-5-contact{
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
      div{
        width: 90% !important;
        
      }
  }

  .about-us{
    margin: 60px 0 !important;
    border-top: 1px solid var(--orange);
    padding-top: 50px ;
    margin-top: 0 !important;
    .about-us-img{
      img{
        width: 300px !important;
      }
    }
    .about-us-text{
      p{
        width: 80% !important;
      }
    }
    .about-us-contact{
      width: 90% !important;
      flex-direction: column !important;
      margin-top: 20px;
      p{
        flex-direction: column !important;
      }
    }
  }

  .container-questions{
    width: 100% !important;
    margin-bottom: 70px !important;
    border-top: 1px solid var(--orange);
    padding: 50px 25px 0 25px;
    margin-top: 0 !important;

    h2{
      font-size: 20px;
      margin: 0 auto;
    }

    p{
      font-size: 15px !important;
    }

    .question{
      padding: 15px 15px !important;
      max-height: 60px ;
      h3{
        width: 85% !important;
        font-size: 14px;
        padding-bottom: 15px !important;
        text-align: justify !important;
      }
      p{
        font-size: 14px !important;
      }

      ol{
        font-size: 14px;
      }
    }
  }

  .container-form{
    margin-top:0 !important ;
    margin-bottom: 50px !important;
    border-top: 1px solid var(--orange);
    padding: 50px 25px 0 25px;
    width: 100%;
    
    .text-form{
      p{
        font-size: 15px;
        text-align: justify;
      }
    }
  }
  .contact-form{
    width: 100% !important;
    grid-template-columns: 1fr !important;
    padding: 30px 20px 50px 20px!important;
    .form__group {
      max-width: 80%;
    }
    button{
      grid-row: 10 !important;
    }
  }
  .contact{
    text-align: center;
    button{
      margin: 0 auto;
    }
  }

  .container-contact{
    flex-direction: column;
    gap: 25px;
    height: min-content;
    
    .contact-left{
      width: 100%;
      text-align: justify;
      height: min-content;
      padding: 70px 0;
      h2{
        width: 80%;
        margin: 0 auto;
        font-size: 25px;
      }
      p{
        width: 80%;
        margin: 0 auto;
        font-size: 16px;
      }
      button{
        margin: 0 auto !important;
      }
    }
    .contact-right{
      width: 100%;
      margin: 30px 0;
      h3{
        text-align: center;
        line-height: 23px;
      }
      .contact-social{
        margin: 0 auto;
      }
    }
  }


}



//animación para el menu de hamburguesa
@keyframes aparecer {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
  
}

@keyframes desaparecer {
  0%{
    opacity: 1;
    display: flex;
  }
  100%{
    opacity: 0;
  }
  
}

.aparecer{
  animation: aparecer 0.3s forwards;
}

.desaparecer{
  animation: desaparecer 0.3s forwards;
}

//Desactivar scroll
.stop-scrolling {
  height: 100%;
  overflow: hidden;
}








